import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import * as Parser from 'rss-parser';
import travel1 from '../../assets/img/travel/paolo-chiabrando-nYlJpAV9Fi4-unsplash.jpg';
import travel2 from '../../assets/img/travel/denise-jans-i7CK4x3WbqU-unsplash.jpg';
import travel3 from '../../assets/img/travel/convertkit-htQznS-Rx7w-unsplash.jpg';
import travel4 from '../../assets/img/travel/nik-guiney-U0J-7yLdsbY-unsplash.jpg';
import travel5 from '../../assets/img/travel/john-mcarthur-X_MOr6oa4-k-unsplash.jpg';
import travel6 from '../../assets/img/travel/andre-ravazzi-7Vv0vRrOG6E-unsplash.jpg';
import travel7 from '../../assets/img/travel/annie-spratt-qyAka7W5uMY-unsplash.jpg';
import travel8 from '../../assets/img/travel/caroline-selfors-r2jpr8MDw0I-unsplash.jpg';
import travel9 from '../../assets/img/travel/stil-TVllFyGaLEA-unsplash.jpg';


const DailyDeals = () => {

  const photos = [travel1,travel2,travel3,travel4,travel5,travel6,travel7,travel8,travel9];
  const [feed,setFeed] = useState([]);





  useEffect(() => {
    let urls = ['https://cors.kravingadventure.com/http://feeds.feedburner.com/FareDealAlert?format=xml','https://cors.kravingadventure.com/http://feeds.feedburner.com/TheFlightDeal?format=xml'];
    let feeds = [];
    let roll = 0;
    window.scrollTo(0, 0);
    let parser = new Parser();
    urls.forEach( (url,i) => {
      parser.parseURL(url, function(err, feed_results) {
        roll++;
        if (err) throw err;
        feed_results.items.forEach( item => {
          feeds.push(item);
        });
        if(roll===urls.length){
          setFeed(feeds);
        }
      });

    })

    return;
  },[]);

  return(
    <Fragment>
      <Helmet>
        <title>Bringing You Daily Travel Savings | Kraving Adventure</title>
      </Helmet>
      <div className="container mt-5">
        <h1 className="text-left">Daily Travel Deals</h1>
        <p>Check out these {feed.length} travel deals for your next adventure. These daily deals don’t last long so make sure you book right away when something catches your interest and works with your schedule.</p>
          <div className="row pt-5">
            {
              feed.map((item,i) => {
                let num = Math.floor(Math.random() * 8);
                let img = photos[num];
                return(
                <div className="col-12 col-md-6 col-lg-4 mb-3" key={i}>
                  {item.content.encoded}
                  <div className="card deal-card">
                    <img className="card-img-top" src={img} alt={item.title} />
                    <div className="card-body daily-deal">
                      <h5 className="card-title">{item.title}</h5>
                      {/* <p>{item.pubDate}</p> */}
                      <p>{
                        item.contentSnippet.replace('[[ This is a content summary only. Visit my website for full links, other content, and more! ]]','')
                        .replace('See below for Read More','')
                        .replace('Read More','')
                        .replace('See below for more','')

                      }</p>
                    </div>

                    <div className="card-footer">
                     <a className="btn bg-tan w-100 p-4" href={item.link} target="_blank" rel="noreferrer">Get This Deal</a>
                    </div>
                  </div>
                </div>
              )})
            }
          </div>



      </div>
    </Fragment>
  )
}


export default withRouter(DailyDeals);
