import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPost } from '../../redux/actions/post.actions';
import { getPost } from '../../api/posts';
import { useParams } from 'react-router-dom';
import { LandingPage, PostBody, PhotoSlideShow, NotFound, Meta } from '../../components';
import 'react-slideshow-image/dist/styles.css'
import { Helmet } from 'react-helmet';
import _ from 'lodash'

const Post = () => {

  const dispatch = useDispatch();
  const { url } = useParams();
  const post = useSelector(state => state.posts.post);

  let   data = {
        "@context": "http://schema.org/",
        "@type": "BlogPosting",
        "headline": `${post.title}`,
        "image": `${post.banner}`,
        "genre":'Travel and Adventure',
        "keywords":`${post.title}`,
        "publisher":'kraving Adventure',
        "url":`${window.location.href}`,
        "datePublished":`${post.travel_date_start}`,
        "dateModified":`${post.travel_date_start}`,
        "description":`${post.title}`,
        "articleBody":`${post.stripped_body}`,
        "author":{
          "@type": "Person",
          "name": "Oksana Kravets"
        },
    };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPost(url,post => dispatch(setPost(post)));
    return dispatch(setPost({}))
  },[url,dispatch]);

  let dates = (post.travel_date_start) ? `${post.travel_date_start}` : '';
  if(post.travel_date_end){
    dates+=' to ' + post.travel_date_end;
  }

  return(
          <Fragment>
            { !_.isEmpty(post) ?
                <Fragment>
                  <Helmet><title>{post.title}</title></Helmet>

                  {
                    post.banner_playlist_id ?
                      <PhotoSlideShow slides={post.banners} title={post.title} />
                    :
                      <div className="page-hero" style={{background:`linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${post.banner})`}}>
                        <h1>{post.title}</h1><br />


                      { post.page_type !== 'landing' &&  <h2 className="text-center">{post.location} <br />{dates}</h2> }


                      </div>
                  }

                  <div className="mt-5 post-body">
                    {
                      post.page_type === 'landing' ?  <LandingPage posts={post.posts} location={post.title} /> :<PostBody post={post} />
                    }
                  </div>
                  <div>
                    <Meta slug={post.slug} data={data} />
                  </div>

                </Fragment>
                :
                <NotFound />
            }
          </Fragment>



  )
}


export default Post;
