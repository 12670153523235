import React, { useEffect} from 'react';
import { connect, useSelector } from 'react-redux';
import { setPosts } from '../../redux/actions/post.actions';
import { getPosts } from '../../api/posts';
import { Link } from 'react-router-dom';
import { PostList, MapSearch, VideoHero } from '../../components';
import vid from '../../assets/video/IMG_0949.mov';
const Home = (props) => {

  const posts = useSelector(state => state.posts.posts);

  useEffect(() => {
    window.scrollTo(0, 0);
      getPosts(props.setPosts)
  },[props.setPosts]);

  return(
    <div className="home">
      <VideoHero title="Kraving Adventure" subtitle="Our jobs fill our pockets, but adventures fill our souls" vid={vid} />
      <div className="container">
        <div className="hello row mt-5 mb-5">
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
            <div className="oksana-n-roman"></div>
          </div>
          <div className="col-12 col-md-6 col-lg-8 d-flex flex-column justify-content-center">
            <h2 >Hello, Bonjour, Bienvenido, Kon'nichiwa</h2>
            <p>We are Oksana and Roman, married and traveling the world. Welcome to our blog. Our jobs fill our pockets, but our travels fill our souls, so every break we get, we are finding new and exciting places to travel and explore. Follow us on our adventures as we try to find new and interesting places in both tourist areas and places off the beaten path.</p>
            <p>Our goal is to not only showcase all the amazing places we visit, but also help you find the best deals and navigate the complicated logistics of travel so you can explore the world too.</p>
            <p><Link to="/about">Get to know us more here!</Link></p>
          </div>
        </div>
      </div>

      <PostList posts={posts.newest} title="Our Newest Adventures" />

      <MapSearch />

      <PostList posts={posts.popular} title="Our Most Popular Adventures" />
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setPosts: posts => dispatch(setPosts(posts)),
})

export default connect(null,mapDispatchToProps)(Home);
