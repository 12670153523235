import { SET_FETCHED_VIDEOS } from '../actions/video.actions';

const INITIAL_STATE = {
  videos:[]
}

const videoReducer = (state=INITIAL_STATE,action) => {
  switch(action.type){
    case SET_FETCHED_VIDEOS:
      return{
        ...state,
        videos:action.payload
      }
    default:
      return state;
  }
}

export default videoReducer;
