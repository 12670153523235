import React, { Fragment } from 'react';
import { Header, Footer } from '../../components';


const Layout = ({children}) => {

  return(
    <Fragment>
      <Header />
      <main id="main">
        { children }
      </main>
      <Footer />
    </Fragment>
  )
}

export default Layout;
