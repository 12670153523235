import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideoLists } from '../../api/videos.js'
import { setFetchedVideos } from '../../redux/actions/video.actions';

const Videos = props => {

const videos = useSelector(state => state.videos.videos)
const dispatch = useDispatch();

useEffect(() => {
  fetchVideoLists(videos => dispatch(setFetchedVideos(videos)));
},[dispatch])

  return(
    <div className="container videos mt-5">
      <h1 className="text-left">Our Adventures Videos</h1>
      <p>Sit back, grab some popcorn, and be prepared to be whisked away on our adventures</p>
      {
        videos &&
        <div className="row">
            {
              videos.map( (videolist,i) => {
                return(
                  <div className="col-12 col-md-6 col-lg-4" key={i}>
                      <h2 className="mt-5" style={{marginBottom:'-10px'}}>{videolist.title}</h2>
                      {
                        videolist.videos.map( (video,i) => (
                          <div className="video-container" key={i}>
                              <iframe title={videolist.title} src={`https://player.vimeo.com/video/${video}`} width="640" height="480" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                          </div>

                      ))
                    }
                </div>
                )

              })
            }
        </div>
      }
    </div>
    )
}

export default Videos
