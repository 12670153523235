import React from 'react';
import { Link } from 'react-router-dom';

const CatCard = ({post}) => {
  return(
  <div className="card cat-card">
    <div className="thumbail-container" style={{backgroundImage:`url(${post.thumbnail})`}}></div>
    <div className="card-body d-flex justify-content-center">
      <Link to={`/${post.url}`}>
        <h5 className="card-title">{post.title}</h5>
      </Link>
    </div>
  </div>
  )
}

export default CatCard;
