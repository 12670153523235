import { SET_POST, SET_POSTS } from '../actions/post.actions';

const INTITAL_STATE = {
  post:{},
  posts:{newest: [], popular: []},
  is_loaded:false,
}

const postReducer = (state=INTITAL_STATE,action) => {
  switch(action.type){
    case SET_POST:
      return{
        ...state,
        post:action.payload,
      }
    case SET_POSTS:
      return{
        ...state,
        posts:action.payload
      }
    default:
      return state;
  }
}

export default postReducer;
