import { combineReducers } from 'redux';


import postReducer from './post.reducer';
import userReducer from './user.reducer';
import navReducer from './nav.reducer';
import videoReducer from './video.reducer';
import shopReducer from './shop.reducer';


export default combineReducers({
  posts:postReducer,
  users:userReducer,
  nav:navReducer,
  videos:videoReducer,
  shop:shopReducer,
});
