import React from 'react';
import { CatCard } from '../../components';


const LandingPage = ({posts, location}) => {

  return(
<div className="container">
<h2>Our Adventures in {location}</h2>

    <div className="row">
      {  posts.length ?
        posts.map( (post,i) => (
          <div className="col-12 col-md-3" key={i}>
            <CatCard post={post} />
          </div>
        ))
        :
          <div className="col-12 mt-5 text-center">
            <h2>No Posts Yet. Check Back Soon!</h2>
          </div>
      }

    </div>
  </div>
  )

}

export default LandingPage;
