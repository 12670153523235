import React, {useState, useEffect, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { PostList, PhotoDeck } from '../';
import  reactStringReplace from  'react-string-replace';

const PostBody = ({post}) => {

  const [story, setStory] = useState([]);





  useEffect(() => {
    if(post.inline_photos && post.inline_photos.length){
      post.inline_photos.forEach( list => {
         post.body = reactStringReplace(post.body, `{{${list.list_id}}}`, (match, i) => (
           <div key={i} className="photo-deck-container"><div id="photo-deck"><PhotoDeck cards={list.photos} /></div></div>
          ));
      })
      setStory(post.body);
    }

    return;
  },[post])

  return(
    <Fragment>
      <div className="adventure-body container">
        <div className="time-location-container">


          </div>
        <div className="mt-4">
          {/* { <div dangerouslySetInnerHTML={{__html: }}></div> } */}
          { story.length ? story.map( piece => { return typeof(piece)==='object' ? piece : ReactHtmlParser(piece) }) :  ReactHtmlParser(post.body) }
        </div>



      </div>
      {
        post.photo_playlist_id &&
        <div className="photo-deck-container">
          <div id="photo-deck">
              <PhotoDeck cards={post.photos} />
          </div>
        </div>
      }

      {   post.like && post.page_type!=='page' &&

        <Fragment>
          <hr className="mt-5" />
        <PostList title="Other Adventures Like This" posts={post.like} />
        </Fragment>

      }
    </Fragment>

  )


}


export default PostBody;
