import React from 'react';
import { PostCard } from '../';

const PostList = ({posts,title}) => {


  return(
    <div className="container">
      <h2 className="mt-5">{title}</h2>
      <div className="row posts">
          {
            posts.map( (post,i) => (
              <div className="col-12 col-md-6 col-lg-3" key={i}>
                <PostCard post={post} />
              </div>
              )
            )
          }
      </div>
    </div>
    )
}

export default PostList;
