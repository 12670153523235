export const SET_NAVIGATION = 'SET_NAVIGATION';


export const setNav = nav => {
  
  return{
    type:SET_NAVIGATION,
    payload:nav
  }
}
