import axios from 'axios';
import { api } from './api';

export const get_amazon = cb => {
  axios.get(`${api}/shop/amazon`)
  .then( res => {
    if(res.data.message==="Success"){
      cb(res.data.products)
    }
  })
  .catch( e => {
    console.error(e.message);
  })
}
