import React from 'react';
import Iframe from 'react-iframe';

const MapSearch = () => {


  return(
    <div className="where-in-the-world">
      <Iframe title="Check out the map of all our adventures" url="https://www.traveledmap.com/en/embedded-map/Ak93wueKSYYimEqVxtNT40zMCPd2?markersColor=hsl(33.94736842105264%2C%2044.705882352941174%25%2C%2033.333333333333336%25)&zoom=[1]&clusteringRadius=30&pitch=0&countriesOpacity=0.2&mapStyle=mapbox://styles/wkolcz/ckk8jpni31f9w17qda19ipvbj" width="100%" height="450px" id="myId" className="myClassname" display="initial" allowFullScreen/>
    </div>
    )
}

export default MapSearch;
