import React from 'react';

const Meta = ({slug,data}) => {

  return (
        <script key={`postJSON-${slug}`} type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}   />
    )
}

export default Meta;
