import React from 'react';
import { NewsLetter } from '../';
import globe from '../../assets/img/globe.png';
import { Link } from 'react-router-dom';

const Footer = () => {

  const _getYear = () => {
    let dt = new Date();
    return dt.getFullYear();
  }
  return(

    <footer className="mt-5">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 col-md-5 mb-5">
            <div className="d-flex flex-column flex-lg-row">
              <div className="footer-icon">
                  {/* <i className="fas fa-globe"></i> */}
                  <img className="globe" src={globe} alt="Kraving Adventure"/>
              </div>
              <div>
                <h5 className="mb-0">Kraving Adventure</h5>
                <p className="greeting-text">We are Oksana and Roman, married and traveling the world. Welcome to our blog. Our jobs 		fill our pockets, but our travels fill our souls, so every break we get, we are finding new and exciting places to travel and explore. Follow us on our adventures as we try to find new and interesting places in both tourist areas and places off the beaten path
  </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 mb-5"></div>
          <div className="col-12 col-md-4 mb-5">
            <NewsLetter />
          </div>
        </div>
      </div>
      <div className="copyright-container container">
        <div>&copy; { _getYear() } Kraving Adventure. All Rights Reserved.</div>
        <div><Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms & Conditions Policy</Link></div>

      </div>
    </footer>
  )
}
export default Footer;
