import { GET_USERS } from '../actions/user.actions';

const INITIAL_STATE = {
  users:[]
}

const userReducer = (state=INITIAL_STATE,action) => {
  switch(action.type){
    case GET_USERS:
      return{
        ...state,
        users:action.payload
      }
    default:
      return state;
  }
}

export default userReducer;
