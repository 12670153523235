import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

const Nav = (props) => {

  const menus = useSelector(state => state.nav.nav);

  return(
      <nav className="navbar navbar-expand-lg">

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i className="fas fa-plane"></i>
      </button>
      <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
        <ul className="navbar-nav">
          <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
        { ReactHtmlParser(menus) }
        <li className="btn-group nav-item"><a role="button" data-toggle="dropdown" className="nav-link dropdown-toggle" href="">Shop</a>
          <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu">
              <li><a class="dropdown-item" href="/travel-gear">Travel Essentials</a></li>
          </ul>
        </li>
        <li className="nav-item"><a className="nav-link" href="/about-us">About Us</a></li>
        </ul>
      </div>
    </nav>
    )
}



export default Nav;
