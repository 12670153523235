import axios from 'axios';
import { api } from './api';


export const getPosts = (cb) => {
  axios.get(`${api}/posts`)
  .then( res => {
    if(res.data.message==='Success'){
      cb(res.data.posts);
    }
  })
  .catch(e => {
    console.error(e.message);
  })

}


export const getPost = (url,cb) => {
  axios.get(`${api}/page/${url}`)
  .then( res => {
    if(res.data.message==='Success'){
      cb(res.data.posts);
    }
  })
  .catch(e => {
    console.error(e.message);
  })
}

export const getMedia = (media_id) => {
  axios.get(`/media/${media_id}`)
  .then( res => {
    return res.data.link
  })
  .catch(e => {
    console.error(e.message);
  })
}
