import { api } from './api';
import axios from 'axios';


export const getNavigation = ( cb ) => {
  axios.get(`${api}/fetch/nav`)
  .then( res => {
    if(res.data.message==='Success'){
      cb(res.data.nav);
    }
  })
  .catch( e => {
    console.error(e.message);
  })
}
