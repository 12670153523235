import React from 'react';
import globe from '../../assets/img/globe404.gif'
import { Link } from 'react-router-dom';

const NotFound = () => {


  return(
    <div className="mt-5 mb-5 container">
        <div className="row">
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center"  style={{height:500}}>
            <img src={globe} alt="Page Not Found"/>
          </div>
          <div className="col-12 col-md-6 text-center d-flex align-items-center justify-content-center flex-column">
            <h3>Uh Oh! Looks like your looking for an adventure we have yet to have.</h3>
            <p><Link to="/">Return home</Link></p>
          </div>
        </div>

    </div>
  )
}

export default NotFound;
