import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { get_amazon } from '../../api/shop';
import { set_amazon_items } from '../../redux/actions/shop.actions';

const AmazonShop = () => {

  const dispatch = useDispatch();
  const amazon = useSelector(state => state.shop.amazon);

useEffect( () => {
  get_amazon(items => dispatch(set_amazon_items(items)));
},[dispatch]);

  return(
    <Fragment>
      <Helmet>
        <title>Essential Travel Gear Suggestions | Kraving Adventure</title>
      </Helmet>
      <div className="container mt-5">
        <h1 className="text-left mt-5">Travel Gear Essentials</h1>
        <p>Check out our curated selections for the best gear to travel with on your next vaction. Most of there items we own ourselves and pack with us on our adventures or we have researched and spent time reading and watching reviews to make sure it will be perfect for your next adventure.</p>
        {
          amazon.map( (item,i) => {
            const prod = item.products.map( (product, i) => {
              return(
                <div className="col-12 col-lg-3 mb-4" key={i}>
                  <div className="card mb-4">
                    <a href={product.url_link} target="_blank" rel="noreferrer" className="text-center">
                      <img src={product.image} className="card-img-top img-fluid" style={{maxHeight:250,width:'auto'}} alt={product.title}/>
                    </a>
                    <div className="card-body">
                      <h5 className="card-title">{product.title}</h5>
                    </div>
                    <div className="card-footer">
                      <a target="_blank" rel="noreferrer" href={product.url_link} style={{textDecoration:'none',fontSize:'1.4em'}} className="btn bg-tan w-100">Buy on Amazon!</a>
                    </div>
                  </div>
                </div>
              )

            })
            return(
              <Fragment key={i}>
                <h2 className="mt-5">{item.category}</h2>
                <div className="row">
                  {prod}
                </div>
              </Fragment>

            )
          })
        }
      </div>
    </Fragment>
  )
}

export default AmazonShop;
