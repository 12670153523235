import React from 'react';
// import vid from '../../assets/video/yoga.mp4';

const VideoHero = ({vid,title,subtitle}) => {


  return(
    <div className="hero home">
      <div className="texture"></div>
      <video playsInline autoPlay muted loop preload="auto" id="vid">
          <source src={vid} type="video/mp4"/>
      </video>
      <div className="site-name-container h-100" >
        <div className="navbar-brand"><h1>{title}</h1></div>
        <p className="tag-line">{subtitle}</p>
      </div>
    </div>
  )
}

export default VideoHero;
