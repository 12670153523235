import { api } from './api';
import axios from 'axios';


export const fetchVideoLists = cb => {
  axios.get(`${api}/videos/list`)
  .then( res => {
    
    if(res.data.message === 'Success'){
      cb(res.data.playlists)
    }
  })
  .catch( e => {
    console.error(e.message);
  })
}
