import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const PhotoSlideShow = ({slides, title}) => {
    return (
      <div className="slide-container">
        <Slide easing="ease">
          {
            slides.map((slide,i) => (
              <div className="each-slide" key={i}>
                <div className="slide-img" style={{background:`linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${slide})`}}>

                </div>
              </div>
            ))
          }
        </Slide>
        <h1>{title}</h1>
      </div>
    )
};

export default PhotoSlideShow;
