import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Home, Layout, Post, Videos, DailyDeals, Privacy, Terms, AmazonShop } from './pages';
import { connect } from 'react-redux';
import { getNavigation } from './api/nav';
import { setNav } from './redux/actions/nav.actions';
import TagManager from 'react-gtm-module';


const history = createBrowserHistory();

const tagManagerArgs = {
  gtmId: 'G-LCF64224ML'
}

TagManager.initialize(tagManagerArgs);

const App = (props) => {

  window.dataLayer.push({
    event: 'pageview'
  });


  useEffect(() => {
    getNavigation(props.setNav);
  },[props.setNav])

  return (
    <Router history={history}>
      <Layout>
        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/videos" render={() => <Videos />} />
          <Route exact path="/travel-gear" render={() => <AmazonShop />} />
          <Route exact path="/daily-deals" render={() => <DailyDeals />} />
          <Route exact path="/privacy" render={() => <Privacy />} />
          <Route exact path="/terms" render={() => <Terms />} />
          <Route path="/:url" render={() => <Post />} />
        </Switch>
      </Layout>
    </Router>
  );
}

const mapDispatchToProps = dispatch => ({
  setNav: nav => dispatch(setNav(nav)),
})

export default connect(null,mapDispatchToProps)(App);
