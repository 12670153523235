import React, { Fragment, useState } from 'react';
import { join_newsletter } from '../../api/newsletter';

const NewsLetter = () => {

  const [email,setEmail] = useState('');
  const [message,setMessage] = useState('');

  const _saveSubmission = e => {
    e.preventDefault();
    if(join_newsletter(email)){
      setMessage('You have successfully subscribed to your adventures!')
    }
  }

  return(
    <Fragment>

      <form onSubmit={(e) => _saveSubmission(e)}>
        <div className="form-group">

          <label htmlFor="email_address"><h5 className="text-center">Come along on our Adventures</h5></label>
          <p className="black text-center">{message}</p>
          <input type="email" required="required" placeholder='Enter Your Email Address' value={email} onChange={e => setEmail(e.target.value)} className="form-control" id="email_address" aria-describedby="emailHelp" />
          <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
        </div>
        <button type="submit" className="btn btn-primary">Subscribe</button>
      </form>
    </Fragment>

  )
}

export default NewsLetter;
