import { LIST_AMAZON } from '../actions/shop.actions';

const INITIAL_STATE = {
  amazon:[]
}

const shopReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case LIST_AMAZON:
      return{
        ...state,
        amazon:action.payload
      }
    default:
      return state;
  }
}

export default shopReducer;
