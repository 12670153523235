import { SET_NAVIGATION } from '../actions/nav.actions';

const INITIAL_STATE = {
  nav:[]
}

const navReducer = (state=INITIAL_STATE,action) => {

  switch(action.type){
    case SET_NAVIGATION:
      
      return{
        ...state,
        nav:action.payload
      }
    default:
      return state;
  }
}

export default navReducer;
