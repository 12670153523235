import React from 'react';
import { Nav } from '../';

const Header = () => {



  return(
    <header id="header">
      <div className="upper-header">
        <div>
           <a href="https://www.instagram.com/kravingadventure/" target="_blank" rel="noreferrer" aria-label="View our adventures on Instagram"><i className="fab fa-instagram-square"></i></a>
        </div>

      </div>
      <div className="lower-header">
        <Nav />
      </div>
    </header>
  )
}

export default Header;
