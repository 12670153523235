import { stripHtml } from "string-strip-html";

export const SET_POST = 'SET_POST';
export const SET_POSTS = 'SET_POSTS';


export const setPost = post => {
  post.stripped_body = post.body ?  stripHtml(post.body).result : '';

  return{
    type:SET_POST,
    payload:post
  }
}

export const setPosts = posts => {
  return{
    type:SET_POSTS,
    payload:posts
  }
}
