import React from 'react';
import { Link } from 'react-router-dom';
const PostCard = ({post}) => {


  return(
    <div className="card post-card-too p-3 mb-4" style={{backgroundImage:`url(${post.thumbnail})`}}>
         <div className="card-body">
            <div className="post-details">
              <Link to={`${post.url}`}><h3 className="pb-3 pt-3 post-title">{post.title}</h3></Link>
              {
                post.travel_date_start && <p><i className="fas fa-plane"></i> Travel Date: {post.travel_date_start}</p>
              }
              {
                post.location && <p><i className="fas fa-map-marker-alt"></i> Location: {post.location}</p>
              }

            </div>.
         </div>
    </div>
  )
}

export default PostCard;
